import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "ingénierie",
    path: "accueil / nos métiers / ingénierie / Ingénieur système"
  },
  title: "Ingénieur système",

	missions:[
		{
		  text: "Optimiser et améliorer les standards et les processus d’un système grâce à son expertise" 
		},
		{
		  text: "Conduit les études techniques des projets" 
		},
		{
		  text: "Solutionner les dysfonctionnements constatés" 
		},
	],
	key_skills: [
		{
		  text: "Expression écrite et orale" 
		},
		{
		  text: "Etre consciencieux" 
		},
		{
		  text: "Analyser et comprendre" 
		},
		{
		  text: "Collaborer" 
		},
		{
		  text: "Etre autonome" 
		},
		{
		  text: "Expertise technique sur les systèmes d’exploitation et/ou infrastructures réseaux." 
		},
		{
		  text: "Connaissances techniques des différents outils et langages de développement orientés outils" 
		},
		{
		  text: "Maîtrise de l’anglais technique obligatoire" 
		},
	],
		prerequisites:[
		{
		  text: "·        Expérience professionnelle validée d’ingénieur système de 2 ans" 
		},
		{
		  text: "Posséder un niveau de connaissances minimum validé par une évaluation technique" 
		},
		{
		  text: "Posséder une certification éditeur dans le domaine d’expertise (Equivalent MCSE, CCNP..)" 
		},
	 ],
	activities:[
		{
		  text: "Analyser le besoin du client" 
		},
		{
		  text: "Assurer l’audit d’infrastructure" 
		},
		{
		  text: "Définir des solutions techniques" 
		},
		{
		  text: "Participer au choix des logiciels et des matériels" 
		},
		{
		  text: "Participer à l’identification des besoins systèmes pour les clients" 
		},
		{
		  text: "Prendre en charge l’installation d’un système" 
		},
		{
		  text: "Participer au design et à la conception d’architecture" 
		},
		{
		  text: "Participer à la rédaction du cahier des charges techniques" 
		},
		{
		  text: "Rédiger les spécifications techniques" 
		},
		{
		  text: "Assurer une veille technologique des produits" 
		},
		{
		  text: "Organiser les connaissances par la rédaction de notes techniques" 
		},
		{
		  text: "Organiser les phases de test et de recette" 
		},
		{
		  text: "Conduire les projets dans le respect des objectifs de qualité, délais et coûts définis" 
		},
	],

}

const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;
